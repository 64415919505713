import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

const LogoFigure = styled.figure`
	border-radius: 50%;
	display: block;
	height: 2rem;
	margin: 0 .5rem 0 0;
	overflow: hidden;
	width: 2rem;
`;

const LogoImage = styled.img`
	height: inherit;
	vertical-align: bottom;
	width: inherit;
`

const Logo = () => (
	<StaticQuery query={graphql`
		{
		  allWordpressWpLogo{
		    edges{
		      node{
		        url{
		          source_url
		        }
		      }
		    }
		  }
		}
	`} render={props => (
		<LogoFigure>
			<LogoImage src={props.allWordpressWpLogo.edges[0].node.url.source_url} alt={`Logo`} />
		</LogoFigure>
	)} />
);

export default Logo;
