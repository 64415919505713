import React from "react"
import { graphql, StaticQuery, Link } from 'gatsby'
import styled from 'styled-components'
import SiteInfo from './SiteInfo'

const MainMenuWrapper = styled.div`
	background-color: #f4f4f4;
	border-bottom: 1px solid rgba(0, 0, 0, .01);
	display: flex;
	padding: 0 1rem;
`

const MainMenuInner = styled.div`
	align-items: center;
	display: flex;
	margin: 0 auto;
	max-width: 960px;
	padding: 1rem 0;
	width: 100%;
`

const MenuItem = styled(Link)`
	color: blue;
	display: block;
	padding: .5rem 1rem;
`

const MainMenu = () => (
	<StaticQuery query={graphql`
		{
		  allWordpressWpApiMenusMenusItems(filter: {
		    slug: {
		      eq: "main"
		    }
		  }) {
		    edges {
		      node {
		        slug
		        items {
		          title
		          object_slug
		        }
		      }
		    }
		  }
		}
	`} render={props => (
		<MainMenuWrapper>
			<MainMenuInner>
				<SiteInfo />
				{props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => (
					<MenuItem to={`/${item.object_slug}`} key={item.title}>
						{item.title}
					</MenuItem>
				))}
			</MainMenuInner>
		</MainMenuWrapper>
	)} />
);

export default MainMenu;
