import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import Logo from "./Logo";

const SiteInfoWrapper = styled.div`
	flex-grow: 1;
`;

const SiteInfoInner = styled.div`
	align-items: center;
	display: flex;
`;

const SiteTitle = styled.div`
	font-weight: bold;
`;

const SiteTagline = styled.div`
	font-size: .875rem;
`;

const SiteInfo = () => (
	<StaticQuery query={graphql`
		{
		  allWordpressSiteMetadata{
		    edges{
		      node{
		        name
		        description
		      }
		    }
		  }
		}
	`} render={props => (
		<SiteInfoWrapper>
			<SiteInfoInner>
				<Logo />
				<div>
					<SiteTitle>
						<div dangerouslySetInnerHTML={{__html: props.allWordpressSiteMetadata.edges[0].node.name}} />
					</SiteTitle>
					<SiteTagline>
						<div dangerouslySetInnerHTML={{__html: props.allWordpressSiteMetadata.edges[0].node.description}} />
					</SiteTagline>
				</div>
			</SiteInfoInner>
		</SiteInfoWrapper>
	)} />
);

export default SiteInfo;
