/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import MainMenu from "./MainMenu";
import styled, { createGlobalStyle } from 'styled-components'
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from "gatsby";

const GlobalStyles = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;700&display=swap');

    body {
        font-family: 'Space Grotesk', sans-serif;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
`

const LayoutWrapper = styled.div`
	margin: 0 auto;
	max-width: 960px;
	padding: 2rem 1rem;
`

const Layout = ({children}) => (
	<StaticQuery query={graphql`
		{
		  allWordpressWpFavicon {
		    edges {
		      node {
		        url {
		          source_url
		        }
		      }
		    }
		  }
		}
	`} render={props => (
		<div>
			<Helmet>
				<link rel={`apple-touch-icon`} href={props.allWordpressWpFavicon.edges[0].node.url.source_url} sizes={`180x180`} />
				<link rel={`icon`} type={`image/png`} href={props.allWordpressWpFavicon.edges[0].node.url.source_url} sizes={`32x32`} />
				<link rel={`icon`} type={`image/png`} href={props.allWordpressWpFavicon.edges[0].node.url.source_url} sizes={`16x16`} />
			</Helmet>
			<GlobalStyles/>
			<MainMenu/>
			<LayoutWrapper>
				{children}
			</LayoutWrapper>
		</div>
	)} />
);

export default Layout
